<template>
  <v-avatar :color="url ? 'transparent' : color" size="36" class="mr-2">
    <img v-if="url" :src="url">
    <span v-else class="initials white--text" v-text="initials" />
  </v-avatar>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    url: { type: String, default: null },
    name: { type: String, required: true },
    fixedColor: { type: Boolean, default: false },
  },
  computed: {
    initials: ({ name }) => `${name.charAt(0)}${name.split(' ')[1].charAt(0)}`,
    color: ({ name, fixedColor }) => {
      if (fixedColor) return 'primary'
      if (name.length < 8) return 'red'
      if (name.length < 9) return 'pink'
      if (name.length < 10) return 'purple'
      if (name.length < 11) return 'deep-purple'
      if (name.length < 12) return 'indigo'
      if (name.length < 13) return 'blue'
      if (name.length < 14) return 'light-blue'
      if (name.length < 15) return 'cyan'
      if (name.length < 16) return 'teal'
      if (name.length < 17) return 'green'
      if (name.length < 18) return 'light-green'
      if (name.length < 19) return 'lime'
      if (name.length < 20) return 'yellow'
      if (name.length < 21) return 'amber'
      if (name.length < 22) return 'orange'
      if (name.length < 24) return 'deep-orange'
      if (name.length < 26) return 'brown'
      if (name.length < 30) return 'blue-grey'
      return 'grey'
    },
  },
}
</script>

<style lang="scss" scoped>
.initials {
  margin-left: 1px;
  letter-spacing: 1px;
}

.v-avatar img {
  width: 100%;
  object-fit: cover;
}
</style>
